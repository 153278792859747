import request from '@/utils/request'


// 查询用户择偶信息列表
export function listStandardInfo(query) {
  return request({
    url: '/user/user-standard-infos/list',
    method: 'get',
    params: query
  })
}

// 查询用户择偶信息分页
export function pageStandardInfo(query) {
  return request({
    url: '/user/user-standard-infos/page',
    method: 'get',
    params: query
  })
}

// 查询用户择偶信息详细
export function getStandardInfo(data) {
  return request({
    url: '/user/user-standard-infos/detail',
    method: 'get',
    params: data
  })
}

// 新增用户择偶信息
export function addStandardInfo(data) {
  return request({
    url: '/user/user-standard-infos/add',
    method: 'post',
    data: data
  })
}

// 修改用户择偶信息
export function updateStandardInfo(data) {
  return request({
    url: '/user/user-standard-infos/edit',
    method: 'post',
    data: data
  })
}

// 删除用户择偶信息
export function delStandardInfo(data) {
  return request({
    url: '/user/user-standard-infos/delete',
    method: 'post',
    data: data
  })
}

export function detailByUserId(data) {
  return request({
    url: '/user/user-standard-infos/detailByUserId',
    method: 'get',
    params: data
  })
}
