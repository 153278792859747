<template>
  <a-drawer width="45%" :label-col="4" :wrapper-col="14" :visible="visible" @close="handleCancel">
    <a-tabs default-active-key="1"  >
      <a-tab-pane key="1" tab="认证信息">
        <detail-list :col="2">
          <detail-list-item term="用户昵称">{{model.userName}}</detail-list-item>
          <detail-list-item term="手机号">{{ model.phone}}</detail-list-item>
          <detail-list-item term="认证师傅类型"><a-tag    color="green">{{model.type | typeFilter  }}</a-tag></detail-list-item>
          <detail-list-item term="用户证件照">
            <template v-if="model.userImg">
              <img-preview   :src="model.userImg" :size="60"  style="margin-right: 8px;" />
            </template>
            <template v-else>未上传</template>


          </detail-list-item>
          <detail-list-item term="生产厂家">{{ model.manufacturer}}</detail-list-item>
          <detail-list-item term="车辆名称">{{ model.carName}}</detail-list-item>
          <detail-list-item term="车辆型号">{{ model.carModel}}</detail-list-item>
          <detail-list-item term="车辆颜色">{{ model.carColor}}</detail-list-item>
          <detail-list-item term="车辆年限（年）"> {{model.carLife }} </detail-list-item>
          <detail-list-item  term="车辆类型"><a-tag    color="red">{{model.carType  | carTypeFilter}}</a-tag></detail-list-item>
          <detail-list-item term="车身结构(座位数)">{{ model.carStructure}}</detail-list-item>
          <detail-list-item term="车牌号">{{ model.carPlate}}</detail-list-item>
          <detail-list-item term="车辆归属地">{{ model.carHomeLocation}}</detail-list-item>
          <detail-list-item term="安全带数量（个）">{{ model.safetyBeltNum}}</detail-list-item>

          <detail-list-item term="驾驶证正面">
            <template v-if="model.drivingLicenceFront">
              <img-preview   :src="model.drivingLicenceFront" :size="60"  style="margin-right: 8px;" />
            </template>
            <template v-else>未上传</template>

          </detail-list-item>
          <detail-list-item term="驾驶证背面">
            <template v-if="model.drivingLicenceReverse">
              <img-preview   :src="model.drivingLicenceReverse" :size="60"  style="margin-right: 8px;" />
            </template>
            <template v-else>未上传</template>

          </detail-list-item>
          <detail-list-item term="车内实景">
            <template v-if="model.insideTheCar">
              <img-preview v-for="item in model.insideTheCar.split(',')" :src="item" :size="60"
                           style="margin-right: 8px;" />
            </template>
            <template v-else>未上传</template>


          </detail-list-item>
          <detail-list-item term="车辆照片">
            <template v-if="model.carPicture">
              <img-preview v-for="item in model.carPicture.split(',')" :src="item" :size="60"
                           style="margin-right: 8px;" />
            </template>
            <template v-else>未上传</template>


          </detail-list-item>
          <detail-list-item term="汽车保险">
            <template v-if="model.automobileInsurance">
              <img-preview v-for="item in model.automobileInsurance.split(',')" :src="item" :size="60"
                           style="margin-right: 8px;" />
            </template>
            <template v-else>未上传</template>

          </detail-list-item>
          <detail-list-item term="汽车安全检查">
            <template v-if="model.vehicleSafetyInspection">
              <img-preview v-for="item in model.vehicleSafetyInspection.split(',')" :src="item" :size="60"
                           style="margin-right: 8px;" />
            </template>
            <template v-else>未上传</template>

          </detail-list-item>
          <detail-list-item term="授权背景调查结果">
            <a-tag    color="green">
              {{model.isAuthorization == 1 ? "同意":"不同意"}}
            </a-tag>
          </detail-list-item>
          <detail-list-item v-if='model.isAuthorization == 1' term="背景调查同意书">{{ model.letterOfConsent}}</detail-list-item>

          <detail-list-item term="详细地址">{{ model.address}}</detail-list-item>


          <detail-list-item term="审核状态"><a-tag    color="green">{{model.status == 0 ? "待审核": (model.status == 1 ? "通过": "未通过")}}</a-tag></detail-list-item>
          <detail-list-item v-if='model.status == 2' term="拒绝原因">{{ model.refusalCause}}</detail-list-item>
          <detail-list-item term="是否上线"><a-tag    color="green">{{model.isOnline == 1 ? "是": "否"}}</a-tag></detail-list-item>

        </detail-list>

      </a-tab-pane>


    </a-tabs>
  </a-drawer>




</template>

<script>

  import DetailList from '@/components/tools/DetailList'
  import ImgPreview from "@/components/crud/table/ImgPreview.vue";
  import dictTag from '@/components/DictTag/index2.vue'
  const DetailListItem = DetailList.Item
  import { getUserCar } from '@/api/userCar/userCar'
  import { detailByUserId } from '@/api/user/standardInfo'
  import Template from '@/views/sms/template/index.vue'
  export default {
    name: "HouseInfo",
    components: {
      Template,
      ImgPreview,
      DetailList,dictTag,
      DetailListItem
    },

    data () {
      return {
        title:'',
        width:1500,
        visible: false,
        disableSubmit: false,
        lessorShow: false,
        lesseeShow: false,

        query: {},
        model: { },



      }
    },
    created() {

    },
    filters: {
      typeFilter(type) {
        let str = ''
        switch (type) {
          case 1: str = '经济';
            break;
          case 2: str = '优享';
            break;
          case 3: str = '专车';
            break;
          case 4: str = '无人';
            break;
          default: str = ''
        }
        return str
      },
      carTypeFilter(carType){
        let str = ''
        switch (carType) {
          case 1: str = '小型车';
            break;
          case 2: str = '中型车';
            break;
          case 3: str = '大型车';
            break;
          case 4: str = '跑车';
            break;
          case 5: str = 'SUVk';
            break;
          default: str = ''
        }
        return str
      }
    },
    methods: {
      initValue(record){
        this.model = record

      },

      close () {
        this.$emit('close');
        this.visible = false;
      },
      handleCancel () {
        this.close()
      },
      back(){
        this.$router.go(-1)
      },
    },
  }
</script>

<style lang="less" scoped>

  .detail-layout {
    margin-left: 44px;
  }
  .text {
    color: rgba(0, 0, 0, .45);
  }

  .heading {
    color: rgba(0, 0, 0, .85);
    font-size: 20px;
  }

  .no-data {
    color: rgba(0, 0, 0, .25);
    text-align: center;
    line-height: 64px;
    font-size: 16px;

    i {
      font-size: 24px;
      margin-right: 16px;
      position: relative;
      top: 3px;
    }
  }

  .mobile {
    .detail-layout {
      margin-left: unset;
    }
    .text {

    }
    .status-list {
      text-align: left;
    }
  }
</style>